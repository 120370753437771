import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	SiteFeatureConfigSymbol,
	PlatformEnvDataProvider,
	SiteWixCodeSdkSiteConfig,
	ExperimentsSymbol,
	Experiments,
	ViewerModelSym,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { name } from '../symbols'

export const siteEnvDataProvider = withDependencies(
	[ExperimentsSymbol, named(SiteFeatureConfigSymbol, name), ViewerModelSym],
	(
		experiments: Experiments,
		{ pageIdToTitle, isWixSite }: SiteWixCodeSdkSiteConfig,
		viewerModel: ViewerModel
	): PlatformEnvDataProvider => {
		const {
			mode,
			site: { isResponsive },
		} = viewerModel

		return {
			platformEnvData: {
				site: {
					experiments,
					isResponsive,
					isWixSite,
					pageIdToTitle,
					mode,
				},
			},
		}
	}
)
